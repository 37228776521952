<template>
  <div class="layer-page-div">
    <div class="big-img-div" v-show="isShowBigImg" @click="onHideBigImgDiv">
      <img ref="bigImg" src="" alt=""/>
    </div>
    <div class="layer-content-div">
      <validation-observer ref="form">
        <div class="item-div">
          <div class="row-div">
            <div class="item-name-div">所属品牌：</div>
            <div class="item-control-div brand-control-div">
              <my-select class="brand-select" :load-more="true" :action="brandNameAction" :params="selectBrandNameParams" :change="onBrandChanged" placeholder-txt="请选择品牌"/>
              <label>{{ partsInfo.brandName }}</label>
            </div>
          </div>
          <div class="tips-div"></div>
        </div>
        <div class="item-div">
          <div class="row-div">
            <div class="item-name-div">所属分组：</div>
            <div class="item-control-div group-select-div">
              <my-select class="group-select" :load-more="true" :action="groupNameAction" :change="onGroupChanged" placeholder-txt="请选择分组"/>
              <my-select class="group-select" :load-more="true" :action="typeNameAction" :params="selectTypeNameParams" :isDefaultLoad="false" :change="onTypeChanged" placeholder-txt="请选择分类"/>
              <label>{{ partsInfo.groupName + "-" + partsInfo.typeName }}</label>
            </div>
          </div>
          <div class="tips-div"></div>
        </div>
        <div class="item-div">
          <validation-provider rules="required|max:200" v-slot="{ errors }" name="partsMemo">
            <div class="row-div">
              <div class="item-name-div">配件说明：</div>
              <div class="item-control-div">
                <label><textarea v-model="partsInfo.introduction" maxlength="200" placeholder="请输入配件说明"/></label>
              </div>
            </div>
            <div class="tips-div err-tips">{{ errors[0] }}</div>
          </validation-provider>
        </div>
        <div class="item-div">
          <div class="row-div">
            <div class="item-name-div">配件图标：</div>
            <div class="parts-img-div">
              <div v-for="(v, k) in partsInfo.img" class="preview-img-div">
                <img class="del-img" src="../../assets/del.png" alt="" @click="onDelOldImg(v, k)">
                <img class="preview-img" :src="`${$imgBaseURL}/${v.url}`" alt="" @click="onShowBigImgDiv($event)">
              </div>
              <div v-for="(v, k) in partsImgData" class="preview-img-div">
                <img class="del-img" src="../../assets/del.png" alt="" @click="onDelImg(k)">
                <img class="preview-img" :src="v" alt="" @click="onShowBigImgDiv($event)">
              </div>
              <div v-show="getImgCount() < 3">
                <label class="upload-img-label" @change="onAddImg($event)">
                  <img class="cursor-el" src="../../assets/picture.png" alt="" />
                  <input class="cursor-el" type="file" />
                </label>
              </div>
            </div>
          </div>
          <div class="tips-div"></div>
        </div>
        <div class="item-div">
          <div class="row-div">
            <div class="item-name-div">认证状态：</div>
            <div class="item-control-div">
              <label><input type="checkbox" v-model="partsInfo.status"></label>
            </div>
          </div>
          <div class="tips-div"></div>
        </div>
        <div class="operate-div">
          <div class="tips-div text-c err-tips">{{ tipMsg }}</div>
          <div class="text-c">
            <input type="button" value="取消" @click="onCancel">
            <input class="save-input" type="button" value="保存" @click="onSave">
          </div>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import MySelect from "../../components/MySelect"
import { getBrandPartsDetails, getBrandNameItems, getBrandPartsGroupNameItems, getBrandPartsTypeNameItems, postBrandPartsEdit } from "../../common/api";

export default {
  components: { MySelect },
  props: {
    layerid: {
      type: String,
      default: ""
    },
    partsId: {
      type: String,
      default: ""
    },
    refreshData: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      brandNameAction: getBrandNameItems,
      groupNameAction: getBrandPartsGroupNameItems,
      typeNameAction: getBrandPartsTypeNameItems,
      selectBrandNameParams: {
        pageNum: 1,
        pageSize: 11,
        type: 2
      },
      selectTypeNameParams: {
        pageNum: 1,
        pageSize: 11,
        groupId: null,
      },
      partsInfo: {
        brandId: 0,
        typeId: 0,
        status: false
      },
      delImgIndex: [],
      partsImg: [],
      partsImgData: [],
      isShowBigImg: false,
      tipMsg: ""
    }
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      getBrandPartsDetails({id: this.partsId})
        .then(data => {
          if(Object.prototype.hasOwnProperty.call(data, "img")){
            let imgObj = [];
            data.img.forEach((v, i) => {
              imgObj.push({index: i, url: v})
            });
            data.img = imgObj
          }
          data.status = data.status === 1;
          this.partsInfo = data;
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("查询品牌配件信息失败");
          }
          this.$layer.close(this.layerid);
        });
    },
    onBrandChanged(item) {
      this.partsInfo.brandId = item.id;
    },
    onGroupChanged(item) {
      this.selectTypeNameParams.groupId = item.id;
    },
    onTypeChanged(item) {
      this.partsInfo.typeId = item.id;
    },
    getImgCount() {
      if(Object.prototype.hasOwnProperty.call(this.partsInfo, "img")){
        return this.partsInfo.img.length + this.partsImgData.length;
      }else{
        return this.partsImgData.length;
      }
    },
    onAddImg(event) {
      if(event.target.files.length < 1){
        return;
      }

      if(!/\.(jpg|jpeg|png)$/.test(event.target.files[0].name.toLowerCase())){
        event.target.value = "";
        this.$layer.msg("图片类型仅支持jpg、jpeg和png格式");
        return;
      }

      let img = event.target.files[0];
      let that = this;
      let reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = function() {
        that.partsImgData.push(reader.result)
        that.partsImg.push(img);
        event.target.value = null;
      };
      reader.onerror = function() {
        that.$layer.msg("读取文件失败，请重试");
      };
    },
    onDelOldImg(item, index) {
      this.delImgIndex.push(item.index);
      this.partsInfo.img.splice(index, 1);
    },
    onDelImg(index) {
      this.partsImg.splice(index, 1);
      this.partsImgData.splice(index, 1);
    },
    onShowBigImgDiv(event) {
      this.$refs.bigImg.src = event.target.src;
      this.isShowBigImg = true;
    },
    onHideBigImgDiv() {
      this.isShowBigImg = false;
    },
    onCancel() {
      this.$layer.close(this.layerid);
    },
    onSave() {
      if(this.getImgCount() < 1){
        this.setTipsMsg("请上传配件图片");
        return;
      }
      if(this.getImgCount() > 3){
        this.setTipsMsg("请配件图片不能大于3张");
        return;
      }

      let formData = new FormData();
      formData.append("id", this.partsInfo.id);
      formData.append("brandId", this.partsInfo.brandId);
      formData.append("typeId", this.partsInfo.typeId);
      formData.append("introduction", this.partsInfo.introduction);
      formData.append("status", this.partsInfo.status ? 1 : 0);
      if(Object.prototype.hasOwnProperty.call(this.partsInfo, "brandId")){
        formData.append("brandId", this.partsInfo.brandId);
      }
      if(Object.prototype.hasOwnProperty.call(this.partsInfo, "typeId")){
        formData.append("typeId", this.partsInfo.typeId);
      }
      this.delImgIndex.forEach(v => {
        formData.append("delImg", v);
      });
      this.partsImg.forEach(v => {
        formData.append("file", v);
      });

      this.$refs.form.validate().then(res => {
        if(res){
          postBrandPartsEdit(formData)
            .then(() => {
              this.refreshData();
              this.$layer.close(this.layerid);
              this.$layer.msg("操作成功");
            })
            .catch(error => {
              if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
                this.$layer.msg(error.data.msg);
              } else {
                this.$layer.msg("操作失败");
              }
            });
        }
      });
    },
    setTipsMsg(msg) {
      this.tipMsg = msg;
      clearTimeout(this.timeCounter);
      this.timeCounter = setTimeout(() => {
        this.tipMsg = "";
      }, 3000);
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .big-img-div {
    position: absolute;
    width: 100%;
    height: calc(100% - 42px);
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      align-items: center;
    }
  }
  .layer-content-div {
    width: 500px;
    margin: 20px auto 0 auto;
    .item-div {
      margin-top: 5px;
      .row-div {
        display: flex;
        align-items: center;
        .item-name-div {
          width: 70px;
          text-align: right;
          margin-right: 15px;
        }
        .item-control-div {
          .brand-select {
            width: 230px;
            height: 33px;
            margin-right: 10px;
          }
          .group-select {
            width: 120px;
            height: 33px;
            margin-right: 10px;
          }
          input[type="checkbox"] {
            width: 30px;
            height: 30px;
            border: 1px solid #dcdcdc;
            vertical-align: middle;
          }
          textarea {
            width: 292px;
            height: 80px;
            resize: none;
            padding: 3px;
            border: 1px solid #dcdcdc;
          }
        }
        .brand-control-div {
          display: flex;
          align-items: center;
        }
        .group-select-div {
          display: flex;
          align-items: center;
        }
        .parts-img-div {
          display: flex;
          .preview-img-div {
            width: 64px;
            height: 64px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            margin-right: 10px;
            .del-img {
              width: 16px;
              height: 16px;
              position: absolute;
              top: 0;
              right: 0;
            }
            .preview-img {
              width: auto;
              height: auto;
              max-width: 100%;
              max-height: 100%;
              align-items: center;
            }
          }
        }
        .operate-attribute-div {
          display: flex;
          align-items: center;
          img {
            width: 30px;
            height: 30px;
            vertical-align: middle;
          }
        }
        .item-header-div {
          margin-top: 5px;
          display: flex;
          label {
            padding: 5px;
            border-top: 1px solid #dcdcdc;
            border-right: 1px solid #dcdcdc;
            border-bottom: 1px solid #dcdcdc;
          }
          label:first-child {
            border-left: 1px solid #dcdcdc;
          }
        }
      }
      .tips-div {
        width: calc(100% - 90px);
        height: 25px;
        margin-left: 90px;
      }
    }
    .parts-attribute-div {
      margin-top: 5px;
      margin-left: 90px;
      .item-header-div {
        display: flex;
        margin-top: 5px;
        label {
          width: 110px;
          height: 25px;
          text-align: center;
        }
      }
      .parts-attribute-item-div {
        display: flex;
        input {
          width: 102px;
          height: 33px;
          padding: 0 3px;
          border: 1px solid #dcdcdc;
        }
      }
    }
    .operate-div {
      margin-top: 20px;
      input {
        width: 120px;
        height: 35px;
        color: white;
        font-size: 16px;
        border: none;
        background: #4676c8;
        margin-top: 10px;
      }
      .save-input {
        margin-left: 5px;
      }
      .tips-div {
        width: 100%;
        height: 25px;
        margin-top: 10px;
      }
    }
  }
}
</style>