<template>
  <div class="child-page-div">
    <div class="page-content-div">
      <table-box ref="tableBox" :tableTitle="'品牌配件管理'" :tableFieldNum="tableFieldNum" :action="action" :params="queryParams" :reloadData.sync="reloadData">
        <template #title-right>
          <div class="search-div">
            <label>
              <select v-model="searchType">
                <option value="1">分组名称</option>
                <option value="2">分类名称</option>
              </select>
            </label>
            <label>
              <input class="search-input" type="text" maxlength="20" v-model="searchMsg" placeholder="搜索相关数据..." />
            </label>
            <input class="search-btn" type="button" value="搜索" @click="onSearch" />
          </div>
        </template>
        <template #table-head>
          <th v-for="(item, index) in tableField" v-show="item.state" :key="index">{{ item.alias }}</th>
          <th v-if="isOperation" class="operation-th">操作</th>
        </template>
        <template v-if="$validateAuthority(2900000,2903000,2903001)" #tableSubHead>
          <th class="table-sub-head-th" :colspan="tableFieldNum">
            <div class="div-btn cursor-el append-btn" @click="onShowAddParts">
              <img src="../../assets/add-data.png" height="14" width="14" alt="" />
              <label class="cursor-el">添加配件</label>
            </div>
          </th>
        </template>
        <template #table-body="props">
          <tr class="cursor-el" @click="onToBrandPartsAttributesPage(props.item)">
            <template v-for="(item, index) in tableField">
              <td v-if="item.isHtml" :key="index" v-show="item.state" v-html="item.value(props.item, item.name)" />
              <td v-else :key="index" v-show="item.state">{{ item.value(props.item, item.name) }}</td>
            </template>
            <td v-if="isOperation">
              <div class="operation-btn-div">
                <span class="cursor-el" @click.stop="onToCommentsPage(props.item)">查看评论</span>
                <span v-if="$validateAuthority(2900000,2903000,2903002)" class="cursor-el" @click.stop="onShowEditParts(props.item)">编辑</span>
                <span v-if="$validateAuthority(2900000,2903000,2903003)" class="cursor-el" @click.stop="onDelParts(props.item)">删除</span>
              </div>
            </td>
          </tr>
        </template>
      </table-box>
    </div>
  </div>
</template>

<script>
import TableBox from "../../components/TableBox";
import BrandPartsAddLayer from "../layer/BrandPartsAddLayer"
import BrandPartsEditLayer from "../layer/BrandPartsEditLayer"
import { getBrandPartsItems, postBrandPartsDel } from "../../common/api";

export default {
  name: "BrandParts",
  components: { TableBox },
  data() {
    return {
      action: getBrandPartsItems,
      reloadData: false,
      searchType: "1",
      searchMsg: "",
      queryParams: {
        type: 0,
        msg: "",
        pageSize: 20
      },
      tableField: [
        { state: true, alias: "配件编号", name: "id", title: "id", isHtml: true, value: this.getObjValue },
        { state: true, alias: "分组名称", name: "groupName", title: "groupName", isHtml: false, value: this.getObjValue },
        { state: true, alias: "分类名称", name: "typeName", title: "typeName", isHtml: false, value: this.getObjValue },
        { state: true, alias: "品牌名称", name: "brandName", title: "brandName", isHtml: false, value: this.getObjValue },
        { state: true, alias: "更新人", name: "updater", title: "updater", isHtml: false, value: this.getObjValue },
        { state: true, alias: "更新时间", name: "updateTime", title: "updateTime", isHtml: false, value: this.getObjValue }
      ]
    }
  },
  computed: {
    isOperation() {
      return this.$hasAuthority(2900000,2903000,[2903002,2903003]);
    },
    tableFieldNum() {
      let num = this.isOperation ? 1 : 0;
      this.tableField.forEach(function(value) {
        if (value.state) {
          ++num;
        }
      });
      return num;
    }
  },
  methods: {
    getObjValue(item, field) {
      if(field === "id"){
        return `<span ${item["status"] === 1 ? "class='success-txt'" : ""}>${item[field]}</span>`;
      }else{
        return item[field];
      }
    },
    onReloadData() {
      this.reloadData = true;
    },
    onSearch() {
      this.queryParams.type = this.searchType;
      this.queryParams.msg = this.searchMsg.trim();
    },
    onShowAddParts() {
      this.$layer.iframe({
        content: {
          content: BrandPartsAddLayer,
          parent: this,
          data: {
            refreshData: this.onReloadData
          }
        },
        area: ["600px", "580px"],
        title: "添加品牌配件"
      });
    },
    onShowEditParts(item) {
      this.$layer.iframe({
        content: {
          content: BrandPartsEditLayer,
          parent: this,
          data: {
            partsId: item.id,
            refreshData: this.onReloadData
          }
        },
        area: ["600px", "580px"],
        title: "编辑品牌配件"
      });
    },
    onDelParts(item) {
      this.$layer.confirm(`是否删除该品牌配件？`, layerid => {
        postBrandPartsDel({ id: item.id })
          .then(() => {
            this.$layer.msg("操作成功");
            this.onReloadData();
            this.$layer.close(layerid);
          })
          .catch(error => {
            this.$layer.close(layerid);
            if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
              this.$layer.msg(error.data.msg);
            } else {
              this.$layer.msg("操作失败");
            }
          });
      });
    },
    onToBrandPartsAttributesPage(item) {
      let rules = Object.prototype.hasOwnProperty.call(item, "rules") ? item.rules : "";
      if(rules.length > 0){
        this.$router.push({ name: "BrandPartsAttributes", query: { id: item.id, rules: rules } });
      }
    },
    onToCommentsPage(item) {
      this.$router.push({ name: "BrandPartsComments", query: { id: item.id } });
    }
  }
}
</script>

<style lang="less" scoped>
.child-page-div {
  width: 100%;
  .page-content-div {
    border-radius: 5px;
    background-color: #ffffff;
    padding: 30px 20px 10px 20px;
    .search-div {
      height: 100%;
      display: flex;
      select {
        width: 110px;
        height: 100%;
        border: 1px solid #dcdcdc;
      }
      .search-input {
        width: 140px;
        height: 33px;
        border: 1px solid #dcdcdc;
        padding: 0 15px;
        margin-left: 5px;
      }
      .search-btn {
        width: 80px;
        height: 100%;
        color: #f4f6f5;
        border: none;
        background: #4676c8;
      }
    }
    .operation-th {
      width: 130px;
    }
    .table-sub-head-th {
      .div-btn {
        float: left;
        width: 88px;
        height: 23px;
        border: 1px solid #e5e5e5;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          margin-right: 10px;
        }
      }
      .append-btn {
        margin-left: 15px;
        margin-right: 10px;
      }
    }
    .img-div {
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        align-items: center;
      }
    }
    .operation-btn-div {
      display: flex;
      justify-content: space-around;
    }
  }
}
</style>